@mixin prevent-hover {
  pointer-events: none;
  cursor: default;
}

@mixin button-disabled {
  @include prevent-hover;
  opacity: 0.5;
}

@mixin button-hover {
  opacity: 0.8;
}

@mixin button-active {
  transform: translateY(1px);
}

@mixin element-transition {
  transition: all 0.2s ease-out;
}

@mixin text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin flex-container($display: flex, $direction: row, $alignItems: flex-start, $justifyContent: flex-start) {
  display: $display;
  flex-direction: $direction;
  align-items: $alignItems;
  justify-content: $justifyContent;
}

          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
        
.container {
  background: #148af5;
  color: white;
  font-weight: bold;
  padding: 8px 24px;
  border-radius: 4px;
  border: none;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    @include button-hover;
  }

  &:active {
    @include button-active;
  }

  &:disabled {
    @include button-disabled;
  }

  .loader {
    width: 20px;
    height: 20px;
  }
}

          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
        
.container {
  width: 480px;
  max-width: 90%;
  height: 100%;
  margin: auto;
  text-align: center;

  .logo {
    width: 100%;
    max-width: 266px;
    padding-top: 32px;
  }

  .button {
    margin-bottom: 16px;
  }
}

          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
        
.container {
  width: 100%;
  padding: 32px 0 16px;

  .break {
    padding-top: 16px;
  }

  .image {
    max-width: 100%;
  }
}
@import url('fonts.scss');

body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1em;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

::placeholder {
  font-family: sans-serif;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
}

a {
  text-decoration: none;
}

html, body, #root {
  height: 100%;
  background: #e7f0f6;
  color: #1f2a35;
}

#root {
  padding: 3rem;
}

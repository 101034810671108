
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
        
.success {
  color: #148af5;
}

.error {
  color: #C62906;
}